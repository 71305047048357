<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <sider-bar />
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-breadcrumb class="bread">
              <a-breadcrumb-item>
                <router-link to="/index"> 控制台 </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 英文系统设置 </a-breadcrumb-item>
            </a-breadcrumb>

            <div
              style="height: calc(100%-100px); width: 100%; overflow-x: auto"
            >
              <a-form :form="form" class="myform" @submit="handleSubmit">


                <h3 style="padding-left: 30px; color: #000">导航栏</h3>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="名称"
                >
                  <a-input
                    style="width: 10%; margin-right:10px;"
                    v-decorator="['ent0']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent1']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent2']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent3']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent4']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent45']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent5']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <br />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent6']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent7']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent8']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent9']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right:10px"
                    v-decorator="['ent10']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>



                <h3 style="padding-left: 30px; color: #000;">基础信息</h3>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="地址"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enadr']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="联系电话"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['entel']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="EMAIL"
                  style="margin-bottom: 50px"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enemail']"
                    :maxLength="200"
                    placeholder="多个关键词请用“,”分开"
                  />
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000;">关于我们</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="标题"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enmtitle']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="简介"
                  style="margin-bottom: 50px"
                >
                  <input
                    type="hidden"
                    v-decorator="['enmcontent']"
                    placeholder=""
                  />
                  <my-editor
                    @HtmlonChange="HtmlonChange"
                    :content="enmcontent"
                  ></my-editor>
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000;">AI简介</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="标题"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaititle']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="简介"
                >
                  <input
                    type="hidden"
                    v-decorator="['enaicontent']"
                    placeholder=""
                  />
                  <my-editor
                    @HtmlonChange="HtmlonChangeAi"
                    :content="enaicontent"
                  ></my-editor>
                </a-form-item>


                <h3 style="padding-left: 30px; color: #000;">AI平台内容</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="会务系统标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['enaititle0']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="会务系统"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaicontent0']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字分析标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['enaititle1']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字分析"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaicontent1']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="智慧云展标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['enaititle2']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="智慧云展"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaicontent2']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字孪生标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['enaititle3']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字孪生"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaicontent3']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>


                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AIGC标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['enaititle4']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AIGC"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['enaicontent4']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item style="padding-left: 110px; padding-bottom: 100px">
                  <a-button type="primary" html-type="submit"> 修改 </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
          <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

import { fetchClass, fetchBase, updateEnBase } from "../api/index";
import global from "../common";
import MyEditor from "../components/MyEditor.vue";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";
export default {
  name: "myuserinfo",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
    MyEditor,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      formItemLayout,
      formTailLayout,
      tsmodelvisible: true,
      maskClosable: false,
      userinfo: [],
      loading: false,
      visiblePasswordModal: false,
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),

      fileList: [],

      enmcontent: "",
      enaicontent: "",
    };
  },
  mounted() {
    let that = this;
    that.fetch();
  },
  methods: {
    HtmlonChange(html) {
      this.form.setFieldsValue({
        enmcontent: html,
      });
    },
    HtmlonChangeAi(html) {
      this.form.setFieldsValue({
        enaicontent: html,
      });
    },

    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      var uuid = s.join("");
      return uuid;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    fetch(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        this.data = res.results;

        // let fileList = [];
        // this.data.imgary.map((item) => {
        //   let uid = that.uuid();
        // });
        // this.fileList = fileList;
        // console.log("fileList:", fileList);

        this.enmcontent = this.data.enmcontent;
        this.enaicontent = this.data.enaicontent;
        this.form.setFieldsValue({


          ent0: this.data.ent0,
          ent1: this.data.ent1,
          ent2: this.data.ent2,
          ent3: this.data.ent3,
          ent4: this.data.ent4,
          ent45: this.data.ent45,
          ent5: this.data.ent5,
          ent6: this.data.ent6,
          ent7: this.data.ent7,
          ent8: this.data.ent8,
          ent9: this.data.ent9,
          ent10: this.data.ent10,


          entel: this.data.entel,
          enadr: this.data.enadr,
          enemail: this.data.enemail,
          enmtitle: this.data.enmtitle,
          enmcontent: this.data.enmcontent,
          enaititle: this.data.enaititle,
          enaicontent: this.data.enaicontent,
          enaicontent0: this.data.enaicontent0,
          enaicontent1: this.data.enaicontent1,
          enaicontent2: this.data.enaicontent2,
          enaicontent3: this.data.enaicontent3,
          enaicontent4: this.data.enaicontent4,

          enaititle0: this.data.enaititle0,
          enaititle1: this.data.enaititle1,
          enaititle2: this.data.enaititle2,
          enaititle3: this.data.enaititle3,
          enaititle4: this.data.enaititle4,
        });
      });
    },



    handleSubmit() {
      const that = this;
      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);

        if (!err) {
          console.info("success");

          let postdata = {
            ent0: values.ent0,
            ent1: values.ent1,
            ent2: values.ent2,
            ent3: values.ent3,
            ent4: values.ent4,
            ent45: values.ent45,
            ent5: values.ent5,
            ent6: values.ent6,
            ent7: values.ent7,
            ent8: values.ent8,
            ent9: values.ent9,
            ent10: values.ent10,

            enadr: values.enadr,
            entel: values.entel,
            enemail: values.enemail,
            enmtitle: values.enmtitle,
            enmcontent: values.enmcontent,
            enaititle: values.enaititle,
            enaicontent: values.enaicontent,
            enaicontent0: values.enaicontent0,
            enaicontent1: values.enaicontent1,
            enaicontent2: values.enaicontent2,
            enaicontent3: values.enaicontent3,
            enaicontent4: values.enaicontent4,

            enaititle0: values.enaititle0,
            enaititle1: values.enaititle1,
            enaititle2: values.enaititle2,
            enaititle3: values.enaititle3,
            enaititle4: values.enaititle4,
          };

          console.log("postdata:", postdata);

          // alert(this.action);

          // return false;

          updateEnBase(postdata).then((res) => {
            console.log("updateCourse:", res);
            let results = res.results;
            if (results.message == "1") {
              that.$message.success("修改成功");
              that.visibleAddModal = false;
              that.form.resetFields();
              that.fetch();
            }
            that.confirmLoading = false;
            that.visibleAddModal = false;
          });

        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ass {
}
.tabletools {
  padding: 20px;
  background: #fff;
}

.divtools {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.divtools .dtileft {
  width: 130px;
}
.divtools .dtiright {
  width: calc(100% - 140px);
  background-color: #fff;
  height: 410px;
  padding: 20px;
  box-sizing: border-box;
}
.fastlist {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;

  flex-wrap: wrap;
}
.fastlist .fastitem {
  width: 100px;
  background-color: #f6f6f6;
  min-height: 100px;
  margin-bottom: 10px;
  border: 1px solid #eee;

  box-sizing: border-box;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #000;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fastlist .fastitem .aicon {
  font-size: 32px;
  color: #1890ff;
  margin-bottom: 5px;
}

.asstable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.photo img {
  height: 140px;
  width: 120px;
  background-color: #fff;
  padding: 5px;
  /* padding-top: 10px; */
  -o-object-fit: contain;
  object-fit: contain;
}

.userinfo {
  line-height: 32px;
  font-size: 14px;
}
.userinfo label {
  color: #999;
}
</style>
